<template>
    <div id="orgList">
        <div class="menu_title">
            <div class="el-icon-s-flag descrip">机构说明：</div>
            <div class="descrip_content">
                <p>1、支持同级公司/部门间的拖拽排序；</p>
                <p>2、每个机构下最多创建30个子机构。</p>
            </div>
            
        </div>

        <div class="menu_content">
            <div class="menu_content_title">
                <h4>组织机构</h4>
            </div>

            <div class="menu_content_content">
                <div class="menu_left">

                    <el-tree
                      class="filter-tree"
                      v-if="treeData.length"
                      :data="treeData"
                      :check-strictly="true" 
                      :highlight-current="true"
                      :props="defaultProps"
                      default-expand-all
                      :filter-node-method="filterNode"
                      @node-click="chooseNode"
                      ref="tree">
                      <span class="custom-tree-node" style="font-size: 14px" slot-scope="{node,data}">
                        <span class="el-icon-office-building" style="margin-right: 4px"></span><span>{{data.orgName}}</span>

                      </span>
                    </el-tree>
                </div>

                <div class="menu_right">
                    <el-button type="primary" style="height: 32px; padding: 0 12px" @click="add">新增机构</el-button>
                
                    <h6 v-if="isShow"><span :class="chooseForm.moduleIcon"></span>{{chooseForm.orgName}} <a @click="editMenu(chooseForm)">编辑</a>
                    
                    <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="delMenu(chooseForm)"
                                    title="是否确认删除机构?"
                                    >
                                    <a slot="reference" style="margin-left: 12px">删除</a>
                                </el-popconfirm>
                    </h6>

                    <p v-if="isShow"> <span>机构编码：{{chooseForm.orgCode}}</span>  <span>序列号：{{chooseForm.sort}}</span> <span>子机构数量：{{tableData.length}}</span> </p>

                    <div style="margin-top: 4px">
                        <el-table border :data="tableData">
                            <!-- <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column> -->
                            <el-table-column prop="orgName" label="机构名称"></el-table-column>
                            <el-table-column prop="orgCode" label="机构编号"></el-table-column>
                            <el-table-column prop="parentOrgName" label="父级机构名称"></el-table-column>
                            <el-table-column prop="sort" label="序列号"></el-table-column>
                            <!-- <el-table-column prop="name" label="机构icon">
                              <template slot-scope="scope">
                                <span :class="scope.row.moduleIcon"></span>
                              </template>
                            </el-table-column> -->
                            <el-table-column prop="name" label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-button @click="editMenu(scope.row)" type="text" size="small">编辑</el-button>
                                    <el-popconfirm
                                      icon="el-icon-info"
                                      icon-color="red"
                                      @confirm="delMenu(scope.row)"
                                      title="是否确认删除机构?"
                                      >
                                      <el-button slot="reference" style="margin-left: 12px" type="text" size="small">删除</el-button>
                                  </el-popconfirm>
                                    
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="float: right; margin-top: 10px">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageInfo.pageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pageInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageInfo.total">
                            </el-pagination>
                    </div>
                </div>
            </div>
        </div>



        <el-dialog :title="dialogTitle" width="40%" :visible.sync="dialogFormVisible">
            <el-form :model="form" ref="cdForm" :rules="rules" style="width: 80%; margin: 20px auto 0;" label-width="80px">
                <el-form-item label="机构名称" prop="orgName">
                    <el-input v-model="form.orgName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="父级机构">
                    <el-popover
                        placement="bottom"
                        width="414"
                        trigger="click"
                        >
                        <el-tree :data="treeData" v-if="treeData.length" default-expand-all :props="defaultProps" @node-click="handleNodeClick"></el-tree>
                        
                        <el-input readonly="" v-model="form.parentOrgName" autocomplete="off" slot="reference"></el-input>
                    </el-popover>
                </el-form-item>

            
                <el-form-item label="排序" prop="sort">
                    <el-input-number v-model="form.sort" controls-position="right" :min="1" :max="100000"></el-input-number>
                    <!-- <el-input v-model="form.sort" type="number" autocomplete="off"></el-input> -->
                </el-form-item>

                
                <el-form-item label="状态" prop="orgStatus">
                    <el-radio v-model="form.orgStatus" label="1">启用</el-radio>
                    <el-radio v-model="form.orgStatus" label="2">停用</el-radio>
                </el-form-item>
                
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addMenu">确 定</el-button>
            </div>
      </el-dialog>
    </div>
</template>

<script>
// import json from '../../assets/json/icon.json'
export default {
    data() {
      return {
        dialogTitle: '新增机构',
        dialogFormVisible: false,
        isShow: false,
        json: require('../../assets/json/icon.json'),
        form: {
            parentOrgName: '',
            parentOrgCode: '',
            orgName: '',
            sort: '',
            orgStatus: '',
        },
        pageInfo: {
            pageNo: 1,
            pageSize: 10,
            total: 0
        },
        rules: {
          orgName: {required: true, message: '请输入机构名称', trigger: 'blur'},
          sort: {required: true, message: '请输入排序', trigger: 'blur'}
        },
        chooseForm: {},
        filterText: '',
        tableData: [],
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'orgName'
        }
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    mounted() {
      this.qryMenu();
      this.qryList();
      if (window.innerHeight){
          var winHeight = window.innerHeight;
      }
      else if ((document.body) && (document.body.clientHeight)){
          var winHeight = document.body.clientHeight;
      }
      document.getElementById("orgList").style.minHeight = (winHeight-90)+'px';
    },
    methods: {
      //查询所有机构
      qryMenu(){
        this.qa.orgList({}).then((res => {
          this.treeData = this.FWZX.buildTree(res.data, 'orgCode', 'parentOrgCode');
          console.log(this.treeData)
        }))
      },
      qryList(){
        //   let params = {
        //       parentOrgCode: 
        //   };
          this.qa.queryOrg(this.pageInfo).then((res) => {
              if(res.respCode == "0000") {
                  this.tableData = res.data;
              } else {
                  this.$message.error(res.msg)
                }
              
          })
      },
      //新增按钮
      add(){
        this.dialogTitle = "新增机构";
        Object.keys(this.form).forEach(key => (this.form[key] = ""));
        this.form.orgStatus = "1";
        this.dialogFormVisible = true;
      },
      //新增机构
      addMenu(){
        this.$refs.cdForm.validate((valid) => {
            if (valid) { 
              if(this.dialogTitle == "新增机构") {
                this.qa.addOrg(this.form).then((res) => {
                  if(res.respCode == "0000") {
                    this.$message({
                        message: '机构新增成功！',
                        type: 'success'
                    });
                    this.qryMenu();
                  } else {
                    this.$message.error(res.msg);
                  }
                  this.dialogFormVisible = false;
                })
              } else {
                this.qa.editOrg(this.form).then((res) => {
                  if(res.respCode == "0000") {
                    this.$message({
                        message: '机构修改成功！',
                        type: 'success'
                    });
                    let orgCode = this.chooseForm.orgCode;
                    if(this.form.id == this.chooseForm.id) {
                      this.chooseForm = this.form;
                    }
                    this.qryMenu();
                    this.qa.queryOrg({parentOrgCode: orgCode, pageNo: this.pageInfo.pageNo, pageSize: this.pageInfo.pageSize}).then((res) => {
                      if(res.respCode == "0000") {
                          this.tableData = res.data;
                          this.pageInfo.total = res.total;
                      } else {
                          this.$message.error(res.msg)
                        }
                      
                  })
                  } else {
                    this.$message.error(res.msg);
                  }
                  this.dialogFormVisible = false;
                })
              }
            } else {
              return false;
            }
        })
        
        
      },

      //选中左侧树
      chooseNode(data){
        // this.$set("")
        this.chooseForm = data;
        this.isShow = true;
        this.qa.queryOrg({parentOrgCode: data.orgCode, pageNo: this.pageInfo.pageNo, pageSize: this.pageInfo.pageSize}).then((res) => {
          if(res.respCode == "0000") {
            this.tableData = res.data;
            this.pageInfo.total = res.total;
          } else {
            this.$message.error(res.msg);
          }
        })
      },

      //修改机构
      editMenu(row){
        this.form = {
            parentOrgName: row.parentOrgName,
            parentOrgCode: row.parentOrgCode,
            orgName: row.orgName,
            sort: row.sort,
            orgStatus: row.orgStatus,
            id: row.id
        };
        this.dialogFormVisible = true;
        this.dialogTitle = "修改机构";

      },
      //删除机构
      delMenu(row){
          this.qa.delOrg({id: row.id}).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.qryMenu();
            this.chooseNode(this.chooseForm);
          })
      },

      handleNodeClick(data){
        console.log(data)
        this.form.parentOrgName = data.orgName;
        this.form.parentOrgCode = data.orgCode;

      },
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      chooseIcon(item){
        this.form.moduleIcon = item;
      },
      handleSizeChange(page){
          this.pageInfo.pageSize = page;
          this.qryList();
      },
      handleCurrentChange(page) {
          this.pageInfo.pageNo = page;
          this.qryList();
      }
    },

}
</script>

<style scoped>
  .menu_title {
    width: 100%;
    height: 68px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .menu_content {
      width: 100%;
      background: #fff;
      margin-top: 12px;
  }
  .menu_content_title {
      height: 50px;
      border-bottom: 1px solid #EFEFEF;
      position: relative;

  }
  .menu_content_title >h4 {
    position: absolute;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    left: 18px;
    bottom: 0;
  }
  .menu_content_content {
      display: flex;
  }
  .menu_left {
      padding: 10px 24px;
      width: 260px;
      border-right: 1px solid #EFEFEF;
  }
  .menu_left >>> .el-input__inner {
      height: 32px;
  }
  .menu_right {
      flex: 1;
      flex-shrink: 0;
      padding: 10px 24px;
  }
  .menu_right >h6 {
      font-size: 16px;
      color: #1D1E1F;
      margin-top: 30px;
  }
  .menu_right >h6 a {
      font-size: 14px;
      color: #1991FC;
      font-weight: 400;
      margin-left: 24px;
      cursor: pointer;
  }
  .menu_right >p {
      margin-top: 10px;
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
  }
  .menu_right >p >span {
      margin-right: 20px;
  }
</style>